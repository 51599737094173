import { EditorSDK } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

import * as api from './api';

interface SantaMembersAppApi {
  refreshMembersAreaApps(): Promise<void>;
}

let editorSDK: EditorSDK;
let appDefinitionId: string;

export const getSantaMembersApi = () => {
  const payload = { appDefinitionId: MEMBERS_AREA };
  const santaMembersApi = editorSDK.application.getPublicAPI('', payload);

  return santaMembersApi as Promise<SantaMembersAppApi>;
};

const getAppApi = () => ({
  refreshMembersAreaApps: async () => {
    const membersEditorApi = await getSantaMembersApi();
    return membersEditorApi.refreshMembersAreaApps();
  },
  openMembersAccountBm: (origin: string) => {
    return editorSDK.editor.openDashboardPanel('', {
      url: `/members-account/?referralInfo=${origin}`,
      closeOtherPanels: false,
    });
  },
});

const editorReady = async (_editorSDK: EditorSDK, appDefId: string) => {
  editorSDK = _editorSDK;
  appDefinitionId = appDefId;
  await editorSDK.editor.setAppAPI('', getAppApi());
};

const exports_ = {
  refreshApp: () => api.refreshApp(editorSDK),
  setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
    api.setBooleanStyleParamForWidgets(editorSDK, appDefinitionId, key, value),
};

export { editorReady, exports_ as exports };
